<template>
    <div>
        <div class="grid md:grid-cols-2 2xl:grid-cols-2 gap-4">
            <div class="border rounded-lg p-2">
                <p class="font-semibold text-base text-center pb-2">Все задачи</p>
                <div class="flex items-center justify-center">
                    <ChartTask
                        legendPosition="bottom"
                        legendWidth="auto"
                        :taskStatistics="taskStatistics"
                        :series="allTasksStatistics"
                        height="300px" />
                </div>
            </div>
            <div class="border rounded-lg p-2">
                <p class="font-semibold text-base text-center pb-2">Завершенные задачи</p>
                <div class="flex items-center justify-center">
                    <ChartCompletedTask 
                        height="200px"
                        :series="completedStatistics"/>
                </div>
            </div>
        </div>
        <div class="font-semibold mb-2 mt-4">Мои задачи</div>
        <TaskTable 
            :organization="organization"/>
    </div>
</template>

<script>
import ChartTask from '@apps/Team/components/Statistics/ChartTask'
import ChartCompletedTask from '@apps/Team/components/Statistics/ChartCompletedTask'
import TaskTable from '@apps/Team/components/Statistics/TaskTable'

export default {
    components: {
        ChartTask,
        ChartCompletedTask,
        TaskTable
    },
    props: {
        organization: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            taskStatistics: {},
        }
    },
    computed: {
        taskCount() {
            let count = 0
            for(const key in this.taskStatistics)
                count += this.taskStatistics[key]

            return count
        },
        completedStatistics() {
            const completedPercent = parseInt(((this.taskStatistics.completed / this.taskCount) * 100).toFixed(2)) || 0
            return [completedPercent]
        },
        allTasksStatistics() {
            const series = []
            for(const key in this.taskStatistics) {
                series.push(this.taskStatistics[key])
            }
            return series
        },
    },
    created() {
        this.getStatisticsByOrganization()
    },
    methods: {
        async getStatisticsByOrganization() {
            const params = {
            }            
            const url = `/users/my_organizations/${this.organization.id}/task_count/`
            try {
                const { data } = await this.$http.get(url, params)
                this.taskStatistics = data
            } catch(error) {
                console.error(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>